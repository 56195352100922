import Footer from '@c/footer'
import { useRouter } from 'next/router'
import Responsive from '../responsive'

const Index = ({ children, className, top = 0 }) => {
  const { pathname } = useRouter()
  // 过滤name字段为空的数据
  const withOutFooter = [
    '/download',
    '/vod/play-thrid/[id]/[ep]',
    '/vod/play-official/[id]/[ep]',
    '/live/[id]',
    '/sports/live/[id]'
  ]
  return (
    <>
      <Responsive className={className}>
        <div style={{ marginTop: top }}>
          {children}
          {withOutFooter.includes(pathname) ? null : <Footer />}
        </div>
      </Responsive>
    </>
  )
}

export default Index
