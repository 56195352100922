import Layout from '@c/layout'
import Button from '@c/button'
import styles from './err.module.scss'

const Index = () => {
  return (
    <Layout>
      <div className="notFound">
        <img loading="lazy" alt="404" src="/api/images/404" width={200} height={167} />
        <p>抱歉！你所访问的页面不存在</p>
        <Button className={styles.errBtn} hover="opacity" link href="/">
          返回首页
        </Button>
      </div>
    </Layout>
  )
}

export default Index
export async function getStaticProps() {
  return {
    revalidate: 60 * 60 * 24,
    props: {
      layoutProps: {
        noSomeMetaInfo: true
      }
    }
  }
}
